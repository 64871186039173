<template>
  <v-card>
    <v-card-title>出勤簿</v-card-title>
    <WorkDetail
      :flgDialog="flgDialog"
      :workdata="selected_workdata"
      :workdatalist="datas_selected_dt"
      :regulartime="[time_start0, time_end0]"
      @flgReset="flgDialog = $event"
      @flgyyyymm="backformdetail"
    >
    </WorkDetail>
    <WorkLeave
      :flgDialog="flgDialogLeave"
      :leavedata="leavedata"
      :yyyymm="workmonth"
      :id="user.id"
      :leavetypelist="leavetypelist"
      @flgReset="flgDialogLeave = $event"
      @flgyyyymm="backformdetail"
    >
    </WorkLeave>
    <WorkHolidayEx
      :flgDialog="flgDialogHolidayEx"
      :holidayex="holidayex"
      :yyyymm="workmonth"
      :id="user.id"
      @flgReset="flgDialogHolidayEx = $event"
      @flgyyyymm="backformdetail"
    >
    </WorkHolidayEx>
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            max-width="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="勤務月"
                v-model="workmonth"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="workmonth"
              no-title
              scrollable
              @input="menu1=false"
              type="month"
              @change="getWorkData"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="selectedusername"
            hide-details
            :items="userlist"
            label="氏名"
            dense
            @change="getDataFromUser"
          >
          </v-select>
          {{user.name}}
        </v-col>
        <v-col cols="1">
          <v-chip v-if="status.confirm===true" color="red" text-color="white">確定済み</v-chip>
          <v-chip v-if="status.confirm===false" color="green" text-color="white">未確定</v-chip>
        </v-col>
        <v-col cols="1">
          <v-menu
            class = "mt-0"
            ref="time_start0"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time_start0"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time_start0"
                label="開始"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu2"
              v-model="time_start0"
              full-width
              @click:minute="$refs.time_start0.save(time_start0)"
            >
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="1">
          <v-menu
            ref="time_end0"
            v-model="menu3"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time_end0"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time_end0"
                label="終了"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="menu3"
              v-model="time_end0"
              full-width
              @click:minute="$refs.time_end0.save(time_end0)"
            >
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="1">
          <v-btn
            dense
            outlined
            color = "blue"
            @click="setWorktimeDefault"
          >
            定時設定
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <table border="1">
          <tr>
            <th v-for ="h of headers" :key="h">{{h}}</th>
          </tr>
          <tr v-for="w of workdata" :key="w">
            <td
              v-if="w.rowspan !== 0"
              :rowspan="w.rowspan"
            >
              <v-btn
                text
                height="25"
                @click="showDetail(w,'new')"
                :color="w.color"
              >
                {{w.dt}}({{comgetWeekday(w.dt)}})
              </v-btn>
            </td>
            <td>
              <v-btn
                text
                @click="showDetail(w,'edit')"
                height="25"
                v-if="w.type !== ''"
              >
                {{w.type}}
              </v-btn>
            </td>
            <td>{{w.time_start}}</td>
            <td>{{w.time_end}}</td>
            <td>{{w.time_break}}</td>
            <td>{{adjustShowItem(w.type,w.time_work)}}</td>
            <td>{{adjustShowItem(w.type,w.time_over_normal)}}</td>
            <td>{{adjustShowItem(w.type,w.time_over)}}</td>
            <td>{{adjustShowItem(w.type,w.time_exover)}}</td>
            <td>{{adjustShowItem(w.type,w.time_offwork)}}</td>
            <td style="width:300px">
              <div v-if="'timetype' in w ">{{w.timetype}}</div>
              <div v-if="w.type==='有休(時)'">有休(時){{w.time_dayoff}}</div>
              {{w.memo}}
            </td>
          </tr>
          <tr>
            <td colspan="5">合計(単位:時間)</td>
            <td>{{Number(summary.sum_work).toFixed(2)}}</td>
            <td>{{Number(summary.sum_over_normal).toFixed(2)}}</td>
            <td>{{Number(summary.sum_over).toFixed(2)}}</td>
            <td>{{Number(summary.sum_exover).toFixed(2)}}</td>
            <td>{{Number(summary.sum_offwork).toFixed(2)}}</td>
            <td></td>
          </tr>
        </table>
      </v-row>
      <v-row>
        <div style="font-size: small;">※2024/02より「残業(割)」(25%割増)、「深夜残業」(25%割増)は「残業」時間に対しての内書きとなります。</div>
      </v-row>
      <v-row>
        <v-col class="d-flex">
          <table>
            <tr><th style="width:200px">項目</th><th>今月合計</th><th>残数</th><th>備考</th></tr>
            <tr>
              <td>有給休暇(時間)</td>
              <td>{{summary.sum_timedayoff}}</td>
              <td>{{summary.sum_timedayoffall}}</td>
              <td
                rowspan="10"
                style="width:300px"
              >
                <v-textarea
                  v-model="status.memo1"
                ></v-textarea>
                <div v-for="txt in (status.memo2).split('\n')" :key = "txt">
                  <div v-if = "txt !==''">
                    {{txt}}<br>
                  </div>
                </div>
              </td>
            </tr>
            <tr><td>有給休暇</td><td>{{Number(summary.count_dayoff).toFixed(1)}}</td><td>-</td></tr>
            <tr><td>有給休暇(合計)</td><td>{{Number(summary.count_totaldayoff).toFixed(3)}}</td><td>{{Number(summary.count_totaldayoffall).toFixed(3)}}</td></tr>
            <tr><td>振替出勤</td><td>{{Number(summary.count_shiftwork).toFixed(1)}}</td><td>-</td></tr>
            <tr><td>振替休暇</td><td>{{Number(summary.count_shiftdayoff).toFixed(1)}}</td><td>{{Number(summary.count_shiftdayoffall).toFixed(1)}}</td></tr>
            <tr><td>欠勤</td><td>{{Number(summary.count_abs).toFixed(1)}}</td><td>-</td></tr>
            <tr><td>特別休暇</td><td>{{Number(summary.count_exdayoff).toFixed(1)}}</td><td>-</td></tr>
            <tr><td>勤務日数(有休含む)</td><td>{{Number(summary.count_work).toFixed(1)}}</td><td>-</td></tr>
            <tr><td>実勤務日数</td><td>{{Number(summary.count_work_ex).toFixed(1)}}</td><td>-</td></tr>
            <tr><td>営業日数</td><td>{{Number(count_businessday).toFixed(1)}}</td><td>-</td></tr>
          </table>
          </v-col>
          <v-col>
            <v-row>
              <v-btn
                v-if="this.status.confirm===false"
                outlined
                color = "green"
                class="ml-2"
                @click="confirmData"
              >確定</v-btn>
              <v-btn
                v-if="this.status.confirm===true"
                outlined
                color = "red"
                class="ml-2"
                @click="confirmData"
              >解除</v-btn>
              <v-btn
                outlined
                class="ml-2"
                @click="getPDF"
              >PDF</v-btn>
              <v-btn
                outlined
                class="ml-2"
                color = "blue"
                @click="showLeave"
              >
                休業入力
              </v-btn>
              <v-btn
                outlined
                class="ml-2"
                color = "blue"
                @click="showHolidayEx"
              >
                有休(特別付与)
              </v-btn>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="status.flgShortWork"
                  label="時短勤務"
                ></v-checkbox>
          </v-col>
        </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>
table {
  border-collapse: collapse;
  border: 0.5px solid gray
}
th {
  border: 0.5px solid gray;
  background-color:beige;
}
td {
  border: 0.5px solid gray;
  padding-left:1em;
  padding-right:1em;
  height: 2em;
  width: 5em;
}
</style>

<script>
import WorkDetail from './WorkDetail.vue'
import WorkHolidayEx from './WorkHolidayEx.vue'
import WorkLeave from './WorkLeave.vue'
import { saveAs } from 'file-saver'

const axios = require('axios')
export default {
  components: {
    WorkDetail,
    WorkLeave,
    WorkHolidayEx
  },
  data: () => ({
    // 対象月の日付リスト
    dt_work: [],
    // 営業日数
    count_businessday: 0,
    // 対象月の勤務データ
    workdata: [],
    // 休業データ
    leavedata: [],
    // 有給休暇特別付与データ
    holidayex: [],
    // 選択した勤務データ
    selected_workdata:
    {
      id: '',
      dt: '',
      type: '',
      time_start: '',
      time_end: '',
      time_break: '',
      memo: '',
      seq: ''
    },
    // 取得した勤務データ/サマリデータ/ステータスデータ
    datas: [],
    // 選択した日付の勤務データ
    datas_selected_dt: [],
    summary: {},
    status: {
      id: '',
      dt: '',
      confirm: false,
      memo1: '',
      memo2: '',
      flgShortWork: false
    },
    // 対象月
    workmonth: '',
    // 対象ユーザー
    user: '',
    // 選択されたusername
    selectedusername: '',
    // select box用のusernameのリスト
    userlist: [],
    // ユーザーデータ(全情報)のリスト
    userlistall: [],
    flgDialog: false,
    flgDialogLeave: false,
    flgDialogHolidayEx: false,
    menu1: false,
    menu2: false,
    menu3: false,
    headers: [
      '日付',
      '勤務種別',
      '開始',
      '終了',
      '休憩',
      '勤務時間',
      '残業',
      '残業(割)',
      '深夜残業',
      '休日出勤',
      '備考'
    ],
    // 祝日データ
    holiday: [],
    leavetypelist: [],
    worktypelist: [],
    time_start0: '',
    time_end0: ''
  }),
  mounted () {
    // マスターデータの取得
    this.getDataMaster()

    // ユーザデータ連携
    this.user = JSON.parse(sessionStorage.getItem('user'))

    // 対象ユーザーとして設定
    this.selectedusername = this.user.username

    // 今月をデフォルトで設定
    this.setThisMonth()

    // データの取得
    this.getWorkData()

    // 管理者用ユーザーデータの取得
    this.getDataUsers()
  },
  methods: {
    // データの取得
    getWorkData: async function () {
      // 対象月の年/月の切り出し
      var y = this.workmonth.slice(0, 4)
      var m = this.workmonth.slice(5, 7)

      // 文字列の日付データ配列の生成
      this.dt_work = this.comgetDateOfMonth(y, m)

      // 営業日数の計算
      this.count_businessday = this.getCountBusinessDay(this.dt_work)

      // 勤務データの取得
      const tmpURL = '/api/workreport?id=' + this.user.id + '&yyyymm=' + this.workmonth
      await axios.get(tmpURL
      ).then(response => {
        if (response.data != null) {
          // 勤務データの配列取得
          this.datas = response.data.detail
          // サマリデータの取得
          this.summary = response.data.summary
          // ワークデータの単純配列を生成
          this.mkWorkDataAll()
          // ステータスデータ
          if (response.data.status) {
            this.status = response.data.status
          } else {
            this.status = {
              id: this.user.id,
              dt: '',
              confirm: false,
              memo1: '',
              memo2: '',
              flgShortWork: false
            }
          }
          // 休業データ
          this.leavedata = response.data.leave
          // 定時データ
          this.time_start0 = response.data.info.work_time_start
          this.time_end0 = response.data.info.work_time_end
          // 特別付与有休
          this.holidayex = response.data.holidayex
        } else {
          // 勤務データの配列取得
          this.datas = []
          // 空のサマリデータの生成
          this.summary = {}
          // ワークデータの単純配列を生成
          this.mkWorkDataAll()
          // ステータスデータ
          this.status = {
            id: this.user.id,
            dt: '',
            confirm: false,
            memo1: '',
            memo2: '',
            flgShortWork: false

          }
          // 休業データ
          this.leavedata = []
          // 有給休暇特別付与
          this.holidayex = []
        }
      }).catch(error => {
        alert(error)
      })
    },
    // 勤務データの単純配列を生成
    mkWorkDataAll: function () {
      // 勤務データのクリア
      this.workdata = []
      // 当該月の日付毎に処理
      for (const d of this.dt_work) {
        // 対象日1日の勤務データリストの取得し、1データづつ配列に入れる
        for (const w of this.mkWorkData(d)) {
          this.workdata.push(w)
        }
      }
    },
    // 対象日１日分の勤務データの生成
    mkWorkData: function (dt) {
      // 指定した日付の勤務データをサーバーから取得したデータから
      // 抽出する
      var work = this.datas.filter(function (data) {
        return data.dt === dt
      })
      // 対象日のデータの存在有無により処理
      if (work.length !== 0) {
        // 件数により表のマージ制御用のデータおよび休日の色データを追加する。
        for (var i = 0; i < work.length; i++) {
          if (i === 0) {
            this.$set(work[i], 'rowspan', work.length)
          } else {
            this.$set(work[i], 'rowspan', 0)
          }
          this.$set(work[i], 'color', this.getHolidayColor(dt))
        }
        return work
      } else {
        // データが存在しない場合には、空のデータを生成する。
        const tmp = [
          {
            id: '',
            dt: dt,
            type: '',
            time_start: '',
            time_end: '',
            time_break: '',
            memo: '',
            rowspan: '1',
            color: this.getHolidayColor(dt)
          }
        ]
        return tmp
      }
    },
    // 作成対象社員変更時の挙動
    getDataFromUser: async function () {
      for (var key in this.userlistall) {
        if (this.userlistall[key].username === this.selectedusername) {
          this.user = this.userlistall[key]
          break
        }
      }
      this.getWorkData()
    },
    // 入力画面の表示
    showDetail: function (data, flgedit) {
      if (this.status.confirm === true) {
        alert('確定済みデータは修正できません。確定解除してください。')
        return
      }

      // 休業期間チェック
      if (this.checkLeave(data.dt) === true) {
        alert('休業期間中です。')
        return
      }

      this.getDataSelectedDt(data.dt)

      this.flgDialog = true
      if (flgedit === 'new') {
        this.selected_workdata =
          {
            id: this.user.id,
            dt: data.dt,
            type: '勤務',
            time_start: this.time_start0,
            time_end: this.time_end0,
            time_break: '01:00',
            memo: '',
            seq: '',
            timetype: ''
          }
      } else {
        // 要素timetype=[午前]/[午後]がない場合、追加
        if (('timetype' in data) === false) {
          data.timetype = ''
        }
        this.selected_workdata =
          {
            id: data.id,
            dt: data.dt,
            type: data.type,
            time_start: data.time_start,
            time_end: data.time_end,
            time_break: data.time_break,
            memo: data.memo,
            seq: data.seq,
            timetype: data.timetype
          }
      }
    },
    // 入力画面からの戻り
    backformdetail: function (yyyymm) {
      this.workmonth = yyyymm
      this.getWorkData()
    },
    // 休業データ画面の表示
    showLeave: function () {
      // 休業入力は管理者/庶務のみ
      if (this.checkPermission() === false) {
        alert('権限がありません。')
        return
      }
      this.flgDialogLeave = true
    },
    // 有休 特別付与 画面の表示
    showHolidayEx: function () {
      // 休業入力は管理者/庶務のみ
      if (this.checkPermission() === false) {
        alert('権限がありません。')
        return
      }
      this.flgDialogHolidayEx = true
    },
    // 確定処理
    confirmData: async function () {
      // 権限チェック
      if (this.checkPermission() === false) {
        alert('権限がありません。')
        return
      }

      // 確定状態の確認と設定
      if (this.status.confirm) {
        this.status.confirm = false
      } else {
        this.status.confirm = true
      }
      this.status.id = JSON.parse(sessionStorage.getItem('user')).id
      this.status.dt = ''
      this.status.memo2 = ''
      const data = {
        target_id: this.user.id,
        target_dt: this.workmonth,
        status: this.status
      }
      alert('確定処理します。')
      await axios.put('/api/workreport', data).then(() => {
        this.getWorkData()
      }).catch(error => {
        alert(error)
      })
    },
    // PDFファイルの生成
    getPDF: async function () {
      const tmpUrl = '/api/workreport?id=' + this.user.id +
                                          '&yyyymm=' + this.workmonth +
                                          '&pdf=1'
      await axios.get(tmpUrl, { responseType: 'blob' }
      ).then(response => {
        // const filename = '出勤簿' + (JSON.stringify(response.headers['content-disposition'])).split('=')[1]
        const filename = '出勤簿' + '(' + this.user.name + ')' + this.workmonth
        const blob = new Blob([response.data], { type: 'application/pdf' })
        saveAs(blob, filename)
      }).catch(error => {
        alert(error)
      })
    },
    // ユーザーリストの取得
    getDataUsers: async function () {
      if (this.checkPermission()) {
        await axios.get('/api/users').then((response) => {
          // 表示用配列の生成
          for (var key in response.data) {
            this.userlist.push(response.data[key].username)
          }
          // オリジナルのデータ
          this.userlistall = response.data
        })
      } else {
        this.userlist.push(this.user.username)
        var tmpU = {}
        tmpU[this.user.id] = this.user
        this.userlistall.push(tmpU)
      }
    },
    getDataMaster: async function () {
      await axios.get('/api/master').then((response) => {
        this.holiday = response.data.holiday
        this.leavetypelist = response.data.leavetypelist
        this.worktypelist = response.data.worktypelist
      })
    },
    // チェック権限
    checkPermission: function () {
      const loginuser = JSON.parse(sessionStorage.getItem('user'))
      if (['庶務', '管理者'].includes(loginuser.permission)) {
        return true
      } else {
        return false
      }
    },
    // 対象月を当月に設定
    setThisMonth: function () {
      var dt = new Date()
      var y = dt.getFullYear()
      var m = ('00' + (dt.getMonth() + 1)).slice(-2)
      this.workmonth = y + '-' + m
    },
    // 表示形式の調整(表示調整/休日の際に空白を表示)
    adjustShowItem: function (dtype, item) {
      if (['有休', '有休(半)', '有休(時)', '振休', '振休(半)', '欠勤', '欠勤(半)', '特休', '特休(半)'].includes(dtype)) {
        return ''
      } else {
        return item
      }
    },
    // 休日の色取得
    getHolidayColor: function (dt) {
      const tmpDt = new Date(dt)
      if (this.comisHoliday(tmpDt) === true) {
        return 'red'
      } else {
        return 'black'
      }
    },
    // 休業関連の入力制御処理(開始終了の整合性)
    checkLeave: function (dt) {
      const tmpd = new Date(dt)
      for (var l of this.leavedata) {
        var tmpds = new Date(l.dt_start)
        var tmpde = new Date(l.dt_end)
        if (tmpd >= tmpds && tmpd <= tmpde) {
          return true
        }
      }
      return false
    },
    // 定時時間のデフォルト設定
    setWorktimeDefault: async function () {
      var vdata =
        {
          id: this.user.id,
          info: {
            work_time_start: this.time_start0,
            work_time_end: this.time_end0
          }
        }
      await axios.post('/api/workreport', vdata).then(() => {
        alert('定時時間を変更しました。')
      }).catch(error => {
        alert(error)
      })
    },
    // 選択した日付の勤務データの配列生成/設定
    getDataSelectedDt: function (dt) {
      this.datas_selected_dt = []
      var i = 0
      while (i < this.datas.length) {
        if (this.datas[i].dt === dt) {
          this.datas_selected_dt.push(this.datas[i])
        }
        i = i + 1
      }
    },
    // 営業日日数のカウント
    getCountBusinessDay: function (dtlist) {
      var tmpC = 0
      for (var i = 0; i < dtlist.length; i++) {
        const tmpDt = new Date(dtlist[i])
        if (this.comisHoliday(tmpDt) === false) {
          tmpC++
        }
      }
      return tmpC
    }
  }
}
</script>
